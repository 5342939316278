import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col, Button } from 'react-bootstrap';

const Heading = styled.div`
  margin: 30px 0;
  text-align: center;
`;

const Title = styled.p`
  font-weight: bold;
  font-size: larger;
  color: #000000;
`;

const StyledCol = styled(Col)`
  padding: 15px;
  border-top: 1px solid #f7941e;
`;

const ServiceTitle = styled.p`
  font-weight: bold;
  color: #000000;
`;

const ServiceContent = styled.div`
  margin-top: 15px;
  text-align: justify;
  padding-bottom: 50px;
`;

const LearnMoreContainer = styled.div`
  padding: 10px 0;
  text-align: left;
  position: absolute;
  bottom: 0;
`;

const StyledButton = styled(Button)`
  background-color: #f6921e !important;
  border-color: #f6921e !important;
`;

const Services = () => (
    <Container fluid="true">
      <Heading>
        <Title>Our Services</Title>
      </Heading>
      <Row>
        <StyledCol sm={12} md={6} lg={6}>
          <div>
            <ServiceTitle>Workshops & Talks</ServiceTitle>
            <ServiceContent>
              <p>We conduct workshops on how to have more happiness and less stress
              at work, how to be a funnier public speaker, and how to make your brand’s
              story more persuasive with the help of wit. We also deliver a motivational
              talk on how to face adversity with a smile when your jokes or life doesn’t
              seem to be going in the correct direction.</p>
            </ServiceContent>
            <LearnMoreContainer>
              <a href="/workshops/" target="_blank"><StyledButton>Learn More</StyledButton></a>
            </LearnMoreContainer>
          </div>
        </StyledCol>
        <StyledCol sm={12} md={6} lg={6}>
          <div>
            <ServiceTitle>The Happiness Program</ServiceTitle>
            <ServiceContent>
              <p>The Happiness Program is a well-designed program to increase the
              Happiness Index of your workplace. The program addresses the factors that
              cause stress at work and help participants cultivate a positive outlook
              towards work and life which in turn increases their efficiency, productivity,
              and willingness to pay their taxes on time.</p>
            </ServiceContent>
            <LearnMoreContainer>
              <a href="/happiness/" target="_blank"><StyledButton>Learn More</StyledButton></a>
            </LearnMoreContainer>
          </div>
        </StyledCol>
      </Row>
      <Row>
        <StyledCol sm={12} md={6} lg={6}>
          <div>
            <ServiceTitle>Stand-Up Comedy</ServiceTitle>
            <ServiceContent>
              <p>We bring laughter to your boardroom and cafeteria.</p>
              <p>We help you identify and recruit professional comics for your
              office events and conferences. We also curate comedy events for
              your brand’s marketing.</p>
            </ServiceContent>
          </div>
        </StyledCol>
        <StyledCol sm={12} md={6} lg={6}>
          <div>
            <ServiceTitle>Content</ServiceTitle>
            <ServiceContent>
              <p>We create witty content.</p>
              <p>&nbsp;</p>
              <p>Whether you are a brand looking for witty social media content,
              e-mailers, brand profile… or someone wanting a funny tinder bio or
              fun speech for you BFFs wedding… we do it all.  As far as the brief
              is ‘make it witty’ we are good.</p>
            </ServiceContent>
          </div>
        </StyledCol>
      </Row>
    </Container>
)

export default Services
