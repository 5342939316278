import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';

const Heading = styled.div`
  margin: 30px 0 30px 0;
  text-align: center;
`;

const Title = styled.p`
  font-weight: bold;
  font-size: larger;
  color: #000000;
`;

const Content = styled.div`
  text-align: justify;
`;

const About = props => (
  <Container fluid="true">
    <Row>
      <Col md={12} xs={12}>
        <div>
          <Heading>
            <Title>About Us</Title>
          </Heading>
          <Content>
            <p>We help funny, slightly-funny, and non-funny people express themselves
            better with the use of humor in business and everyday life.</p>
            <p>&nbsp;</p>
            <p>We believe humor is more than just entertainment - it can be used to
            break down barriers, improve communication, build confidence and empower.
            We have developed enjoyable workshops that help utilise the positive
            effects of humor at work and in life.</p>
          </Content>
        </div>
      </Col>
    </Row>
  </Container>
)

export default About
