import React from 'react';
import Layout from '../components/layout';
import About from '../components/about';
import Services from '../components/services';
import Clients from '../components/clients';

class IndexPage extends React.Component {

  render() {
    return (
      <Layout>
        <About />
        <Services />
        <Clients />
      </Layout>
    )
  }
}

export default IndexPage;
