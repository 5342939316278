import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col, Image } from 'react-bootstrap';

const Heading = styled.div`
  margin: 30px 0 30px 0;
  text-align: center;
`;

const Title = styled.p`
  font-weight: bold;
  font-size: larger;
  color: #000000;
`;

const StyledImage = styled(Image)`
  display: inline !important;
  height: 50% !important;
  max-width: 75%;
`;

const StyledCol = styled(Col)`
  text-align: center;
  height: 100px;
`;

const Clients = () => (
  <div>
    <Container fluid="true">
      <Heading>
        <Title>Our Clients</Title>
      </Heading>
      <Row>
        <StyledCol sm={12} md={4} lg={4}>
          <StyledImage className="img-responsive" src="/images/logo1.png"/>
        </StyledCol>
        <StyledCol sm={12} md={4} lg={4}>
          <StyledImage className="img-responsive" src="/images/logo2.png"/>
        </StyledCol>
        <StyledCol sm={12} md={4} lg={4}>
          <StyledImage className="img-responsive" src="/images/logo3.png"/>
        </StyledCol>
      </Row>
      <Row>
        <StyledCol sm={12} md={4} lg={4}>
          <StyledImage className="img-responsive" src="/images/logo4.png"/>
        </StyledCol>
        <StyledCol sm={12} md={4} lg={4}>
          <StyledImage className="img-responsive" src="/images/logo5.png"/>
        </StyledCol>
        <StyledCol sm={12} md={4} lg={4}>
          <StyledImage className="img-responsive" src="/images/logo6.png"/>
        </StyledCol>
      </Row>
      <Row>
        <StyledCol sm={12} md={4} lg={4}>
          <StyledImage className="img-responsive" src="/images/logo7.png"/>
        </StyledCol>
        <StyledCol sm={12} md={4} lg={4}>
          <StyledImage className="img-responsive" src="/images/logo8.png"/>
        </StyledCol>
        <StyledCol sm={12} md={4} lg={4}>
          <StyledImage className="img-responsive" src="/images/logo9.png"/>
        </StyledCol>
      </Row>
    </Container>
  </div>
)

export default Clients
